import React from "react";
import { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { withAuthRedirect } from "../../hoc/withAuthRedirect";
import {
  getMarketplaceProjects,
  deleteProjectFromMarketplace,
  getMarketplaceDevelopers,
  getMarketplaceFreelancers,
  getMarketplaceLeads,
} from "../../store/marketplace";
import { hire, payForChat } from "../../store/chat";
import MarketplaceProjects from "./MarketplaceProjects";

const MarketplaceProjectsContainer = (props) => {
  useEffect(() => {
    if (props.companyType === 1) {
      props.getMarketplaceFreelancers();
      props.getMarketplaceLeads();
    }

    if (props.isSingleDeveloper) {
      props.getMarketplaceFreelancers()
    } else {
      props.getMarketplaceDevelopers();
      props.getMarketplaceProjects();
    }
  }, []);

  return (
    <MarketplaceProjects
      companyId={props.companyId}
      connect_points={props.connect_points}
      companyType={props.companyType}
      companyVerificationChecks={props.companyVerificationChecks}
      marketplace={props.marketplace}
      closeMarketplaceRequestForm={props.closeMarketplaceRequestForm}
      getMarketplaceProjects={props.getMarketplaceProjects}
      deleteProjectFromMarketplace={props.deleteProjectFromMarketplace}
      hire={props.hire}
      payForChat={props.payForChat}
    />
  );
};

let mapStateToProps = (state) => ({
  companyId: state.auth.company_info.id,
  companyType: state.auth.company_info.type,
  companyVerificationChecks: state.auth.companyVerificationChecks,
  marketplace: state.marketplace,
  closeMarketplaceRequestForm: state.form.closeMarketplaceRequest,
  connect_points: state.auth?.company_info?.connect_points,
});

export default compose(
  connect(mapStateToProps, {
    getMarketplaceProjects,
    deleteProjectFromMarketplace,
    getMarketplaceDevelopers,
    getMarketplaceFreelancers,
    getMarketplaceLeads,
    hire,
    payForChat,
  }),
  withAuthRedirect,
)(MarketplaceProjectsContainer);
